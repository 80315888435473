/* Login page css fixed */
.Pages-fullPage-2 {
  align-items: unset !important;
  padding: 90px 0 !important;
}

.Pages-fullPage-2:before {
  background-color: rgba(0, 0, 0, 0) !important;
}

.recaptchaContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgetPassword {
  text-align: end;
  margin-bottom: 30px;
}

.switchLanguageButton {
  padding: 10px;
  font-size: 18px;
  color: #1457c4;
}

@media (min-width: 599px) {
  .switchLanguageButton {
    color: #86bacf;
  }
}

.fixedBTColorLogin {
  background-color: #43425d !important;
}

.fixedBTColorReg {
  color: #43425d !important;
}

.mobileLanguageSwitchBT {
  padding: 10px;
  font-size: 18px;
}

.loadingBt {
  margin-right: 20px;
}

.fixedDropdown {
  margin: 0 0 17px 0 !important;
  position: relative !important;
  padding-top: 27px !important;
  vertical-align: unset !important;
}
/* ================ */
/* end of Login css */

/* Modal component css fixed */
.modalBt {
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 0.3125rem 1px;
  padding: 12px 30px;
  position: relative;
  font-size: 12px;
  min-width: auto;
  min-height: auto;
  text-align: center;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  will-change: box-shadow, transform;
  touch-action: manipulation;
  border-radius: 3px;
  letter-spacing: 0;
  text-transform: uppercase;
  vertical-align: middle;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14),
    0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
  background-color: #43425d;
}

.modalBt:hover {
  background-color: #63636a;
}

.modalBtCancel {
  background-color: transparent !important;
  color: #43425d !important;
  transition: none !important;
  box-shadow: none !important;
}

.modalBtCancel:hover {
  color: #63636a !important;
}

.modalBtExit {
  background-color: transparent !important;
  color: #2e86c1 !important;
  transition: none !important;
  box-shadow: none !important;
}

.modalBtExit:hover {
  color: #5dade2 !important;
}

.modalBtDelete {
  background-color: #e74c3c !important;
  color: #fff !important;
  border: none;
  cursor: pointer;
  margin: 0.3125rem 1px;
  padding: 12px 30px;
  position: relative;
  font-size: 12px;
  min-width: auto;
  min-height: auto;
  text-align: center;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  will-change: box-shadow, transform;
  touch-action: manipulation;
  border-radius: 3px;
  letter-spacing: 0;
  text-transform: uppercase;
  vertical-align: middle;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14),
    0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}

.modalBtDelete:hover {
  background-color: #ec7063 !important;
  color: #fff !important;
  border: none;
  cursor: pointer;
  margin: 0.3125rem 1px;
  padding: 12px 30px;
  position: relative;
  font-size: 12px;
  min-width: auto;
  min-height: auto;
  text-align: center;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  will-change: box-shadow, transform;
  touch-action: manipulation;
  border-radius: 3px;
  letter-spacing: 0;
  text-transform: uppercase;
  vertical-align: middle;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14),
    0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}

/* .modalContent {
    top: 40% !important;
} */

.modalContent > h2 {
  font-size: 28px !important;
  font-weight: 500 !important;
}

.GridItem-grid-204 {
  text-align: center;
}

/* Sidebar component css fixed */
.sider_logo_img {
  width: 85%;
}

.fixOveflow {
  overflow-x: hidden;
  padding: 5px;
}

/* @media (max-width: 959.95px) {
    .HeaderLinks-popperNav-223 > div ul li{
        color: #000 !important;
    }
} */

.style_navpills {
  border-radius: 0px !important;
}

/* @media(min-width: 960){
    .project_donut {
        background-color: #1457c4;
        height: 200px;
    }
    
    .project_map {
        background-color: #1457c4;
    }
}

.project_donut {
    background-color: #FFF;
    height: auto;
}

.project_map {
    background-color: #FFF;
    height: auto;
} */

.change_tabColor {
  background: linear-gradient(60deg, #384d97, #384d97) !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 172, 193, 0.4);
}

.card_imageBody {
  padding: 0 !important;
}

.card_imagePic {
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.card_imageFooter {
  margin-top: 0 !important;
  flex-direction: column;
  align-items: flex-start !important;
}

.card_loading {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.form_radio {
  justify-content: flex-start;
  align-items: baseline;
  padding-left: 15px;
}

.form_radio_control {
  justify-content: flex-start;
  align-items: baseline;
}

.resize_imageupload {
  position: relative;
  /* max-width: 270px !important; */
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12),
    0 7px 10px -5px rgba(0, 0, 0, 0.12) !important;
}

.rdt input.form-control {
  font-size: 14px;
  padding-top: 10px;
}

.image_container {
  position: relative;
}

.close_button_hover {
  position: absolute !important;
  top: 0;
  right: 0;
}

.file_name {
  position: absolute;
  bottom: 0;
  left: 6px;
  right: 6px;
}

.MuiPaper-elevation2 {
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12) !important;
}

.fileinput .thumbnail {
  display: inline-block;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  max-width: 250px;
  max-height: 200px;
}

.fileinput .thumbnail > img {
  width: 250px;
  height: 200px;
}

/* Markkk */
.MuiTableCell-alignRight {
  text-align: left !important;
  flex-direction: row !important;
}
.MuiTableCell-root {
  padding: 14px 4px 14px 16px !important;
}

.MuiTableSortLabel-root {
  width: 140px !important;
}

.MuiTableSortLabel-icon {
  font-size: 20px !important;
}
.MuiInputBase-input {
  /* font-size: 10px !important; */
}

.dropdownHouseholdsec3 {
  font-size: 10px !important;
}
.extraClass {
  width: 330px !important;
  font-size: 0.85rem !important;
  pointer-events: auto !important;
  opacity: 0.2 !important;
}

label {
  color: "#000000";
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
